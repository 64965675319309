<template>
  <div class="form-urls" :class="{skeleton: !state.loaded}">
    <div class="wrapper">
      <div class="head">
        <span class="title">신청서 URL</span>
      </div>
      <div class="body">
        <ul class="tight">
          <li v-for="(c1, idx1) in state.constraints" :key="idx1">
            <div class="title">
              <span>{{ c1.title }}</span>
            </div>
            <table class="table table-hover" v-if="c1.children.length">
              <thead>
              <tr>
                <th>
                  <span>번호</span>
                </th>
                <th>
                  <span>이름</span>
                </th>
                <th>
                  <span>URL</span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(c2, idx2) in c1.children" :key="idx2">
                <td>
                  <span>{{ idx2 + 1 }}</span>
                </td>
                <td>
                  <span>{{ c2.title }}</span>
                </td>
                <td>
                  <a class="pointer-u" :href="c2.url" target="_blank" rel="noopener noreferrer" title="해당 신청서로 이동">{{ c2.url }}</a>
                  <span class="pointer ml-2 badge badge-primary" @click="copy(c2.url)">복사</span>
                </td>
              </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import ArchiveMultiSelect from "@/components/ArchiveMultiSelect";
import Number from "@/components/Number";
import http from "@/scripts/http";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalArchiveUrls";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, ArchiveMultiSelect},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xm"});

      state.constraints = [{
        title: "Wait a moment",
        children: [
          {title: "Wait a moment", url: "Greeting. Please wait a moment."},
          {title: "Wait a moment", url: "Greeting. Please wait a moment."},
          {title: "Wait a moment", url: "Greeting. Please wait a moment."},
          {title: "Wait a moment", url: "Greeting. Please wait a moment."}]
      }];

      state.loaded = false;
      http.get(`/api/admin/archives/${modalParams.archiveId}/constraints`).then(({data}) => {
        state.loaded = true;

        const constraints = data.body;
        state.constraints = constraints.filter(c => !c.parentId);

        for (let i in state.constraints) {
          const parent = state.constraints[i];
          parent.children = [];

          constraints.filter(c1 => c1.parentId === parent.id).forEach((c2) => {
            c2.url = `${modalParams.archiveUrl}?constraintId=${c2.id}`;
            parent.children.push(c2);
          });
        }
      });
    });

    const state = reactive({
      loaded: false,
      constraints: []
    });

    const copy = (value) => {
      lib.copyToClipboard(value);
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const modalParams = store.getters.modalParams(component);

    return {component, state, modalParams, copy};
  }
});
</script>

<style lang="scss" scoped>
.form-urls {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }
  }

  .body {
    > ul > li {
      padding-top: $px10;
      padding-bottom: 15px;

      > .title {
        padding-bottom: $px15;
      }

      table {
        border-bottom: $px1 solid #e9ecef;

        thead th {
          background: $colorBackground;
        }
      }
    }
  }

  &.skeleton {
    .body {
      > ul > li > .title > span {
        @include skeleton
      }

      table {
        th, td {
          a, span {
            @include skeleton
          }
        }
      }
    }
  }
}
</style>